import $ from 'jquery'
import CalendarTracker from 'components_sites_calendar/helpers/CalendarTracker'
import ReportingTracker from 'components_reporting/helpers/ReportingTracker'
import SettingsTracker from 'components_shared/helpers/SettingsTracker'
import CompanyUserManagementTracker
  from '../../components_company_user_roles/helpers/CompanyUserManagementTracker'

// Reporting
const reportingComponentName = 'Reporting'
const reportingTracker = ReportingTracker.forComponent(reportingComponentName)

// User Management
const userManagementComponentName = 'UserManagement'
const userManagementTracker = CompanyUserManagementTracker.forComponent(userManagementComponentName)

// Advice List
const adviceListComponentName = 'AdviceList'
const adviceListTracker = CalendarTracker.forComponent(adviceListComponentName)

// Locale Select
const localeSelectComponentName = 'UserLocaleInput'
const localeSelectTracker = SettingsTracker.forComponent(localeSelectComponentName)

$(document).ready(() => {
  // Reporting
  memoizedExposureTracker('reportingExposed', 'nav-reporting-icon', () => {
    reportingTracker.track(reportingComponentName, 'exposed')
  })

  // User Management
  memoizedExposureTracker('userManagementExposed', 'operatorUserManagement', () => {
    userManagementTracker.track(userManagementComponentName, 'exposed')
  })
  memoizedExposureTracker('contributorUserManagementExposed', 'contributorUserManagement', () => {
    userManagementTracker.track(userManagementComponentName, 'exposed')
  })
  memoizedExposureTracker('prospectsCountExposed', 'prospects-count', () => {
    userManagementTracker.track(userManagementComponentName, 'prospects count exposed')
  }, true)

  // Advice List
  memoizedExposureTracker('adviceListExposed', 'nav-advice-list-icon', () => {
    adviceListTracker.track(adviceListComponentName, 'exposed')
  })

  // Locale Select
  memoizedExposureTracker('localeSelectExposed', 'localeDropdownMenuButton', () => {
    localeSelectTracker.track(localeSelectComponentName, 'exposed')
  })
})

/**
 * Uses the localStorage to memoize whether an element has been previously exposed or not. If not previously
 * exposed, run the callback function
 * @param cacheKey {string} the key used to check the localStorage for existence
 * @param elementId {string} the id of element to check if exists
 * @param callback {()=>void} the callback function - used once if the element has never been
 * @param forceWrite {boolean} skip cache check and force writes to storage
 */
const memoizedExposureTracker = (cacheKey, elementId, callback, forceWrite = false) => {
  const element = document.getElementById(elementId)
  if (element) {
    const elementExposed = localStorage.getItem(cacheKey)
    if (elementExposed !== 'true' || forceWrite) {
      setTimeout(() => callback(), 0)
      localStorage.setItem(cacheKey, 'true')
    }
  }
}
